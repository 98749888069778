import React from "react";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Home from "./components/Home";
import Foot from "./components/Foot";

function App() {
  return (
    <Router>
      <div className="App">
        <SpeedInsights/>
        <Analytics/>
        <header>
          <Header />
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
          <Foot />
      </div>
    </Router>
  );
}

export default App;
