import React from "react";

const Services = () => {
  return (
    <div className="container mx-auto p-6 bg-orange-500 items rounded-lg mb-2">
      <h1 className="text-4xl font-bold mb-6 text-center text-white">
        Nuestros Servicios
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="flex flex-col md:flex-row items-center bg-white p-4 rounded-lg shadow-lg">
          <div className="md:w-1/2 md:pr-6">
            <img
              src="/images/slide1.jpg"
              alt="Limpieza de Oficinas"
              className="rounded-lg shadow-lg w-full h-auto"
            />
          </div>
          <div className="md:w-1/2 md:pl-6 mt-6 md:mt-0">
            <h2 className="text-2xl font-bold mb-4 text-orange-600 text-left">
              Limpieza profunda de casa, departamento, negocio u oficina en el
              momento que lo requieras.
            </h2>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center bg-white p-4 rounded-lg shadow-lg">
          <div className="md:w-1/2 md:order-2 md:pl-6">
            <img
              src="/images/slide5.jpg"
              alt="Limpieza Residencial"
              className="rounded-lg shadow-lg w-full h-auto"
            />
          </div>
          <div className="md:w-1/2 md:order-1 mt-6 md:mt-0">
            <ul className="text-2xl font-bold mb-4 text-orange-600 text-right">
              Mantenimiento:{" "}
            </ul>
            <li className="text-orange-600 mb-4 ml-10 text-justify">
              Electricidad
            </li>
            <li className="text-orange-600 mb-4 ml-10 text-justify">Plomería</li>
            <li className="text-orange-600 mb-4 ml-10 text-justify">Pintura</li>
            <li className="text-orange-600 mb-4 ml-10 text-justify">
              Carpintería
            </li>
            <li className="text-orange-600 mb-4 ml-10 text-justify">
              Albañilería
            </li>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center bg-white p-4 rounded-lg shadow-lg">
          <div className="md:w-1/2 md:pr-6">
            <img
              src="/images/imagen5.jpg"
              alt="Limpieza de Comercios"
              className="rounded-lg shadow-lg w-full h-auto"
            />
          </div>
          <div className="md:w-1/2 md:pl-6 mt-6 md:mt-0">
            <h2 className="text-2xl font-bold mb-4 text-orange-600 text-left">
              Ofrecemos soluciones integrales de limpieza para tu empresa, casa
              o negocio.
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
