import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import StickyIcon from "./StickyIcon";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="flex flex-row items-center justify-between p-4 bg-white">
      <RouterLink to="/" className="flex items-center">
        <img
          src="/logo2.png"
          alt="Master Mop Limpieza Profunda"
          className="h-20 w-20 md:h-24 md:w-24"
        />
      </RouterLink>
      <div className="md:hidden">
        <button
          onClick={toggleMenu}
          className="text-gray-700 hover:text-blue-600 focus:outline-none"
        >
          <svg
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>
      </div>
      <nav className={`md:flex md:items-center space-x-4 ${isOpen ? "block" : "hidden"} md:block`}>
        <ScrollLink
          to="section2"
          smooth={true}
          duration={500}
          className="cursor-pointer text-gray-700 hover:text-blue-600 p-2 flex items-center justify-center text-justify"
          onClick={toggleMenu}
        >
          Acerca de
        </ScrollLink>
        <ScrollLink
          to="section3"
          smooth={true}
          duration={500}
          className="cursor-pointer text-gray-700 hover:text-blue-600 p-2 flex items-center justify-center text-justify"
          onClick={toggleMenu} 
        >
          Servicios
        </ScrollLink>
        <ScrollLink
          to="section4"
          smooth={true}
          duration={500}
          className="cursor-pointer text-gray-700 hover:text-blue-600 p-2 flex items-center justify-center text-justify"
          onClick={toggleMenu} 
        >
          ¿Cómo funciona?
        </ScrollLink>
      </nav>
      <StickyIcon />
    </header>
  );
};

export default Header;
