import React, { useState } from 'react';

const VideoLoop = () => {
    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
        setImageError(true);
    };

    return (
        <div className="relative w-full h-96 mt-5">
            {imageError ? (
                <div className="w-full h-full bg-orange-600"></div>
            ) : (
                <img src="/images/video1.gif" alt="video1" className="w-full h-full object-cover" onError={handleImageError} />
            )}
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="absolute inset-0 flex items-center justify-center">
                <h1 className="flex text-4xl font-bold text-white text-center">Servicios de Limpieza Profunda y Mantenimiento en Querétaro</h1>
            </div>
        </div>
    );
}

export default VideoLoop;