import React from 'react';
import VideoLoop from './VideoLoop';
import About from '../components/About';
import Services from '../components/Services';
import ComoFunciona from './ComoFunciona';

const Home = () => {
  return (
    <div className="container mx-auto">
      <section id="section1">
        <VideoLoop />
      </section>
      <section id="section2">
        <About />
      </section>
      <section id="section3">
        <Services />
      </section>
      <section id="section4">
        <ComoFunciona />
      </section>
    </div>
  );
}

export default Home;
