import React from "react";

const About = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-4xl font-bold mb-6 text-center text-gray-600">
        Acerca de Master Mop
      </h1>
      <div className="flex flex-col md:flex-row items-center mb-6">
        <div className="md:w-1/2 md:pr-6">
          <img
            src="/images/imagen1.png"
            alt="Limpieza Profunda"
            className="rounded-lg shadow-lg w-full h-auto"
          />
        </div>
        <div className="md:w-1/2 md:pl-6 mt-6 md:mt-0 mr-6">
          <h2 className="text-2xl font-bold mb-4">¿QUIÉNES SOMOS?</h2>
          <p className="text-gray-700 mb-4 text-justify">
            Somos una empresa 100% mexicana dedicada a asegurar espacios limpios
            y proveer mantenimiento, ya sea para tu casa, oficina o negocio.
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center mb-6">
        <div className="md:w-1/2 md:pr-6 md:order-2">
          <img
            src="/images/Imagen2.png"
            alt="Equipo de Limpieza"
            className="rounded-lg shadow-lg w-full h-auto"
          />
        </div>
        <div className="md:w-1/2 md:pl-6 md:order-1 mt-6 md:mt-0 mr-6">
          <h2 className="text-2xl font-bold mb-4">¿QUÉ OFRECEMOS?</h2>
          <p className="text-gray-700 mb-3 text-justify">
            Contamos con los mejores expertos en limpieza y mantenimiento. Nos
            aseguramos de ofrecer el más alto servicio de calidad utilizando los
            mejores recursos en el mercado.
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 md:pr-6">
          <img
            src="/images/Imagen4.png"
            alt="Clientes Satisfechos"
            className="rounded-lg shadow-lg w-full h-auto"
          />
        </div>
        <div className="md:w-1/2 md:pl-6 mt-6 md:mt-0 mr-6">
          <h2 className="text-2xl font-bold mb-4 text-left">
            NOS DISTINGUE...
          </h2>
          <ul className="list-disc pl-5 text-gray-700 text-justify">
            <li className="mb-2">Compromiso con la calidad</li>
            <li className="mb-2">Responsabilidad y profesionalismo</li>
            <li className="mb-2">Eficiencia</li>
            <li className="mb-2">Atención al cliente excepcional</li>
            <li className="mb-2">Precios competitivos</li>
            <li className="mb-2">Seguimiento</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default About;
